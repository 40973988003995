@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

* {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  /* --primary-color: #206a5d; */
  --primary-color: #00a990;
  --secondary-color: #1b1c25;
  --light-color: #ebecf1;
}

body {
  background-color: var(--primary-color);
}

button {
  padding: 0.6rem 1.2rem;
  width: 13%;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  transition: 0.5s all ease-in-out;
  background-color: var(--secondary-color);
  color: var(--light-color);
}

button:hover {
  transition: 0.5s all ease-in-out;
  background-color: var(--light-color);
  color: var(--secondary-color);
  cursor: pointer;
}

input {
  width: 100%;
  padding: 0.4rem 0.8rem;
  border-radius: 2px;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

input:focus {
  outline: 1px solid var(--secondary-color);
}

select {
  width: 100%;
  padding: 0.4rem 0.8rem;
  border-radius: 2px;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

select:focus {
  outline: 1px solid var(--secondary-color);
}

input[type='file'] {
  margin-bottom: 0.4rem;
  width: 100%;
  padding: 0.4rem 0;
  border-radius: 2px;
  border: none;
  border: none;
  background: none;
  color: var(--secondary-color);
}

.main-content {
  display: flex;
  height: 100vh;
}

.sidebar {
  padding: 2rem;
  background-color: var(--secondary-color);
  width: 22%;
  flex: 0 0 250px; /* Sidebar width: 250px, it won't grow or shrink */
}

.content-wrapper {
  width: 100%;
  padding: 2rem;
  flex: 1; /* Content wrapper will take the remaining available space */
  overflow-y: auto;
}

.sidebar-title {
  display: flex;
  align-items: center;
}

.sidebar-title h2 {
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.content-wrapper p {
  text-align: justify;
}

.login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.login h1 {
  color: var(--secondary-color);
  margin-bottom: 0.4rem;
}

.login p {
  color: var(--light-color);
  margin-bottom: 2rem;
}

.login input {
  width: 25%;
  margin-bottom: 1rem;
  border: none;
  border-radius: 4px;
  padding: 0.6rem 1.2rem;
}

.login input:focus {
  outline: 1px solid var(--secondary-color);
}

.login button {
  padding: 0.6rem 1.2rem;
  width: 13%;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  margin-top: 1rem;
  transition: 0.5s all ease-in-out;
  background-color: var(--secondary-color);
  color: var(--light-color);
}

.login button:hover {
  transition: 0.5s all ease-in-out;
  background-color: var(--light-color);
  color: var(--secondary-color);
  cursor: pointer;
}

.active a {
  color: var(--primary-color);
}

.menu-ul li {
  list-style: none;
  padding: 1.2rem 0rem;
  display: flex;
  align-items: center;
}

.menu-ul li a {
  color: var(--light-color);
  display: flex;
  align-items: center;
  text-decoration: none;
}

.active a {
  color: var(--primary-color) !important;
}

.menu-ul li a svg {
  margin-right: 0.6rem;
}

.section-title h2 {
  color: var(--light-color);
  margin-bottom: 2rem;
}

.content-inner {
  padding: 1rem 0;
}

/* CONTENT CSS */
.dashboard-content {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.dashboard-content a {
  text-decoration: none;
  color: unset;
}

.tile {
  padding: 2rem;
  background-color: var(--light-color);
  margin-bottom: 1rem;
  margin-right: 1rem;
  width: 325px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: var(--secondary-color);
  transition: 0.3s all ease-in-out;
}

.tile:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  transition: 0.3s all ease-in-out;
}

.upper-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.upper-content h4 {
  color: var(--secondary-color);
}

.cat-listings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.4rem;
  background-color: var(--light-color);
  margin-bottom: 1.2rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: var(--light-color);
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 40%;
}

.modal-content h2 {
  color: var(--secondary-color);
  margin-bottom: 1rem;
}

.inputs-for-modal {
  margin-bottom: 0.8rem;
}

.inputs-for-modal input {
  margin-bottom: 0.8rem;
}

.inputs-for-modal select {
  margin-bottom: 0.8rem;
}

.modal-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0.4rem;
}

.modal-buttons button {
  margin-right: 0.8rem;
  width: 25%;
}

.modal-buttons button:hover {
  background-color: var(--secondary-color);
  color: var(--light-color);
}

.edit-del svg {
  margin-left: 2rem;
  cursor: pointer;
}

.listed-products-img {
  width: 65px;
  height: auto;
}

.fw-listed-products {
  font-weight: 500;
  margin-bottom: 0.4rem;
}

.listed-products-entries {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.prods-listings {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.4rem;
  background-color: var(--light-color);
  margin-bottom: 1.2rem;
  /* height: 40vh; */
}

.listings-table {
  width: 100%;
  text-align: left;
  background-color: var(--light-color);
  padding: 1.4rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.listings-table tbody {
  width: 100%;
}

.listings-table th {
  width: 100%;
}

.listings-table tr {
  width: 100%;
  display: flex;
  align-items: center;
}

.listings-table td {
  width: 100%;
  font-size: 13px;
  margin-right: 0.4rem;
}

.for-mb {
  margin-top: 0.8rem;
}

.edit-del-prods svg {
  margin-right: 1.5rem;
  margin-left: 0;
  margin-top: 1rem;
}

.product-card-listings-main {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.product-card-listings-inner {
  margin-bottom: 0.6rem;
  margin-right: 0.6rem;
  display: flex;
  flex-direction: column;
  width: 245px;
  background-color: #ebecf1;
  padding: 1.5rem;
  border-radius: 8px;
}

.product-lists-img-div {
  height: 70px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.8rem;
}

.product-det-entry {
  margin-bottom: 0.5rem;
}

.product-link {
  text-decoration: none;
  color: unset;
}

.product-link button {
  width: 50%;
}

.listed-components {
  display: flex;
}

.single-component {
  margin-left: 0.6rem;
  margin-bottom: 0.6rem;
  padding: 2rem;
  background-color: var(--light-color);
  width: fit-content;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.single-component h4 {
  margin-bottom: 1rem;
}

.single-component img {
  width: 250px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.replace-btn {
  width: 30% !important;
}

.contact-item {
  padding: 1rem;
  background-color: var(--light-color);
  margin-right: 0.6rem;
  border-radius: 8px;
}
